import React from "react"

class ExamOneGroupFour extends React.Component {
    render() {
        return <a
            className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/dxuooWswEcwXCKGw9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwoGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/oZJbQnsj4iAe1qu27"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamThreeGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/pTYutf4ZUzZbktfe6"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamFourGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/U428rVbvkwbqDXjo6"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamFiveGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/zuXFQJfHTwL95sZU6"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamSixGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/WesojqdsRmRftsiQ9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamSevenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/yMdz4UoF23eos7PT9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamEightGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/Bu3cNuBrqKhBPuEt6"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamNineGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/maBjgX3y91tTB9Mw5"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/XBv2BMJ75wDwpHC17"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamElevenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/h5M5HiabCFtmmiNL7"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwelveGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/eegnyLfRRtFBquks8"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamThirteenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/tAgitRCPMXMBN3db7"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamFourteenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/mfrvuwXc733jzTyHA"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamFifteenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/ZZ3cyKDeRvdTsg2q8"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamSixteenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/HFFi6u5iFnfQ4Yw87"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamSeventeenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/bXhtMNAeSu7q8DNN7"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamEighteenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/XfifsENfVwcxyMpJ6"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamNineteenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/sTbkiNKFnYqWbP2B8"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/wdcZyS3aFwsvRweD9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyOneGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/PkhzbR6cULgSqSvT7"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyTwoGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/CQyNGSPRwuis96kv9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyThreeGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/Kp89vMN8urTE63rq9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyFourGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/gBptE9oGQsqexrVU8"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyFiveGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/kpZDjrz5kA7y1Tst5"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentySixGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/tKheVn78uXZ2x5Hh8"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentySevenGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/YRvoxutx8wPNc85d6"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyEightGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/t8tMCtojpmQj2YmG9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamTwentyNineGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/MpddJg5Ww2TFKn2C6"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamThirtyGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/jiAFJ2mHqeizx19u9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamThirtyOneGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/3DhHm2kANTiHVVW3A"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamThirtyTwoGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/6x39SkS7vUVzSHxv7"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamThirtyThreeGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/WASRPTGxR9oKcXvK9"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class ExamThirtyFourGroupFour extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/49224arcZM9Xb4d3A"
            target="_blank"
        >
            Перейти к экзамену по 4 группе
</a>;
    }
}

class RandomExamFour extends React.Component {
    randomize(myArray) {
        return myArray[Math.floor(Math.random() * myArray.length)];
    }
    render() {
        var arr = [<ExamOneGroupFour />, <ExamTwoGroupFour />, <ExamThreeGroupFour />, <ExamFourGroupFour />, <ExamFiveGroupFour />, <ExamSixGroupFour />, <ExamSevenGroupFour />, <ExamEightGroupFour />, <ExamNineGroupFour />, <ExamTenGroupFour />, <ExamElevenGroupFour />, <ExamTwelveGroupFour />, <ExamThirteenGroupFour />, <ExamFourteenGroupFour />, <ExamFifteenGroupFour />, <ExamSixteenGroupFour />, <ExamSeventeenGroupFour />, <ExamEighteenGroupFour />, <ExamNineteenGroupFour />, <ExamTwentyGroupFour />, <ExamTwentyOneGroupFour />, <ExamTwentyTwoGroupFour />, <ExamTwentyThreeGroupFour />, <ExamTwentyFourGroupFour />, <ExamTwentyFiveGroupFour />, <ExamTwentySixGroupFour />, <ExamTwentySevenGroupFour />, <ExamTwentyEightGroupFour />, <ExamTwentyNineGroupFour />, <ExamThirtyGroupFour />, <ExamThirtyOneGroupFour />, <ExamThirtyTwoGroupFour />, <ExamThirtyThreeGroupFour />, <ExamThirtyFourGroupFour />]
        return <div>{this.randomize(arr)}</div>;
    }
}

export default RandomExamFour