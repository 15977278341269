import React from "react"

class ExamOneGroupThree extends React.Component {
    render() {
        return <a
            className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/avSPw2p2Nd4nX35k6"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwoGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/DBobB5r85pekwjkP9"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamThreeGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/517d3asCZHe7dyis8"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamFourGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/GtyAtaokrGDwS4uW8"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamFiveGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/esFptbD8FsQPXUUWA"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamSixGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/6AdsAo4xTYi16uAEA"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamSevenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/u3N2Rf8fPQCQMKWEA"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamEightGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/8hNU4SopfTKHSx4j7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamNineGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/iRu94caiPuNJ4edJ7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/k5VxawKnvM2FKJeD9"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamElevenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/H6xYppxK8eqLXZWRA"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwelveGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/PbM4CnrQ1MCgBvCw7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamThirteenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/xbraGCp2mXygZvky9"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamFourteenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/noKjZTLY3BvM15pY7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamFifteenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/z1yF7hkf6eKMaLbx5"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamSixteenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/qyGCbzotVdJScd1u9"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamSeventeenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/f7W69WZkmBDA3nZJ7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamEighteenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/ikDQPWWM8fEQCT72A"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamNineteenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/K28HyAYPN5WyR8Tj8"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/SLAkaH3qVYQ7mK686"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyOneGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/MCSNg38gCf2EdNSq9"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyTwoGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/BNtXccv4CGz28TiXA"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyThreeGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/ie3qREwsGagADcQ78"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyFourGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/3HkZBvqyiiNQ4FQU8"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyFiveGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/W3VqVYYc3snNee3F7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentySixGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/bfM8JfiPwqnHGjbV7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentySevenGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/zLUi8YXRjvc1uwZY7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyEightGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/CN65qct7yBKxxNjk7"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class ExamTwentyNineGroupThree extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/W4wdMZgcW5idgnAv6"
            target="_blank"
        >
            Перейти к экзамену по 3 группе
</a>;
    }
}

class RandomExamThree extends React.Component {
    randomize(myArray) {
        return myArray[Math.floor(Math.random() * myArray.length)];
    }
    render() {
        var arr = [<ExamOneGroupThree />, <ExamTwoGroupThree />, <ExamThreeGroupThree />, <ExamFourGroupThree />, <ExamFiveGroupThree />, <ExamSixGroupThree />, <ExamSevenGroupThree />, <ExamEightGroupThree />, <ExamNineGroupThree />, <ExamTenGroupThree />, <ExamElevenGroupThree />, <ExamTwelveGroupThree />, <ExamThirteenGroupThree />, <ExamFourteenGroupThree />, <ExamFifteenGroupThree />, <ExamSixteenGroupThree />, <ExamSeventeenGroupThree />, <ExamEighteenGroupThree />, <ExamNineteenGroupThree />, <ExamTwentyGroupThree />, <ExamTwentyOneGroupThree />, <ExamTwentyTwoGroupThree />, <ExamTwentyThreeGroupThree />, <ExamTwentyFourGroupThree />, <ExamTwentyFiveGroupThree />, <ExamTwentySixGroupThree />, <ExamTwentySevenGroupThree />, <ExamTwentyEightGroupThree />, <ExamTwentyNineGroupThree />]
        return <div>{this.randomize(arr)}</div>;
    }
}

export default RandomExamThree