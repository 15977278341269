import React from "react"
import Helmet from "react-helmet"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Navbar from "../components/navbar"
import RandomExamTwo from "../components/random-exam-group-two"
import RandomExamThree from "../components/random-exam-group-three"
import RandomExamFour from "../components/random-exam-group-four"
import Layout from "../components/layout"
import "./software.scss"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileDownload } from "@fortawesome/free-solid-svg-icons"
import ListGroup from 'react-bootstrap/ListGroup'

const SoftwarePage = () => (
    <Layout>
        <Helmet>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
            />
        </Helmet>
        <SEO title="Тесты по электробезопасности" />

        <Navbar />
        <section className="quizzes-section-telis">
            <Container fluid>
                <Row>
                    <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="align-self-center my-5 pt-5"
                    >
                        <h1 style={{
                            color: "var(--textNormal)",
                            wordBreak: "break-all",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                        }} className="font-weight-bold text-center mb-0">
                            Тесты по электробезопасности
        </h1>
                        <h2 className="pt-5 w-90 text-center mx-auto" style={{
                            color: "var(--textNormal)",
                            transition: "color 0.2s ease-out, background 0.2s ease-out",
                            maxWidth: "1140px",
                        }}>Вопросы для работников организаций потребителей электрической энергии (обслуживающих организаций). Непромышленные потребители. Напряжение до и выше 1000 В</h2>
                        <Container>
                            <Row>
                                <p className="lead py-5" style={{
                                    color: "var(--textNormal)",
                                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                                }}>
                                    В тесты включены вопросы по категориям:
        </p>
                                <ul>
                                    <li>Правила по охране труда при эксплуатации электроустановок</li>
                                    <li>Правила технической эксплуатации электроустановок потребителей</li>
                                    <li>Правила устройства электроустановок</li>
                                    <li>Мероприятия по оказанию первой помощи</li>
                                    <li>Правила работы с персоналом в организациях электроэнергетики Российской Федерации</li>
                                </ul>

                            </Row>
                            <h2 className="text-center mt-5">Нормативные правовые акты</h2>
                            <div className="d-grid text-center my-2">
                            <div className="border border-success py-4 my-3"><a
                    target="_blank"
                    className="p-3 m-3 font-weight-bold"
                    href="../../../documents/НПА.docx"
                  ><FontAwesomeIcon
                  className="mr-1 fa-lg"
                  icon={faFileDownload}
                />НПА</a></div>
                            </div>
                            <h2 className="text-center mt-5">Вопросы для подготовки</h2>
                            <div className="d-grid text-center my-2">
                            <div className="border border-success py-4 my-3"><a
                    target="_blank"
                    className="p-3 m-3 font-weight-bold"
                    href="../../../documents/Вопросы по ЭБ  2 группа.xlsx"
                  ><FontAwesomeIcon
                  className="mr-1 fa-lg"
                  icon={faFileDownload}
                />Вопросы для подготовки по 2 группе</a></div>
                            <div className="border border-success py-4 my-3"><a
                    target="_blank"
                    className="p-3 m-3 font-weight-bold"
                    href="../../../documents/Вопросы  по ЭБ 3 группа.xlsx"
                  ><FontAwesomeIcon
                  className="mr-1 fa-lg"
                  icon={faFileDownload}
                />Вопросы для подготовки по 3 группе</a></div>
                            <div className="border border-success py-4 my-3"><a
                    target="_blank"
                    className="p-3 m-3 font-weight-bold"
                    href="../../../documents/Вопросы по ЭБ  4 группа.xlsx"
                  ><FontAwesomeIcon
                  className="mr-1 fa-lg"
                  icon={faFileDownload}
                />Вопросы для подготовки по 4 группе</a></div>
                            </div>

                            <h2 className="text-center mt-5">Тестирование перед экзаменом</h2>
                            <div className="d-grid text-center my-2">
                            <Link className="p-3 m-3 font-weight-bold" to="/prepare-group-2"><div className="border border-info py-4 my-3">Билеты по 2 группе</div></Link>
                            <Link className="p-3 m-3 font-weight-bold" to="/prepare-group-3"><div className="border border-info py-4 my-3">Билеты по 3 группе</div></Link>
                            <Link className="p-3 m-3 font-weight-bold" to="/prepare-group-4"><div className="border border-info py-4 my-3">Билеты по 4 группе</div></Link>
                            </div>

                            <h2 className="text-center mt-5">Экзамен</h2>
                            <div className="d-grid text-center my-2">
                                <div className="border border-primary py-4 my-3"><RandomExamTwo></RandomExamTwo></div>
                                <div className="border border-primary py-4 my-3"><RandomExamThree></RandomExamThree></div>
                                <div className="border border-primary py-4 my-3"><RandomExamFour></RandomExamFour></div>
                                
                            </div>
                            
                            
                        </Container>

                    </Col>

                </Row>
            </Container>
        </section>
    </Layout>
)

export default SoftwarePage
