import React from "react"

class ExamOneGroupTwo extends React.Component {
    render() {
        return <a
            className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/xd3uR6Xkzs3Kyd3J6"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamTwoGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/rxtuxRDBjznm1xvQ6"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamThreeGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/ackPRr9gHuMWFKhK6"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamFourGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/9uLhTnyjd5TEExdc6"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamFiveGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/CB58TMKPHGu79FkG6"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamSixGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/GdRoQXbSGca53unx8"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamSevenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/P1UeHeSW23PQLG7X6"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamEightGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/LFfZiTy7AJG7b8Si9"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamNineGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/mg7vt2G6v2gt7fYZ9"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamTenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/bsPtgvH8tjiPT3k67"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamElevenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/QUGNcNFqiqZfNVbJ7"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamTwelveGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/NKD1oMysL7XU613YA"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamThirteenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/YVU7212nJtLJYJkBA"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamFourteenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/dziEYCwKZCmwMoaz6"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamFifteenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/NSrZCJGBJeeEJAoL9"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamSixteenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/B6WFbqtNGzWoC7EZ9"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamSeventeenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/aLiH9iZ2zQ67nkxP8"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamEighteenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/BLU5ovjcars5Gvs99"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamNineteenGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/1zs9SpxCa6cdXj4z9"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class ExamTwentyGroupTwo extends React.Component {
    render() {
        return <a
        className="p-3 m-3 font-weight-bold"
            href="https://forms.gle/cUmBSZTeTkTfbo7B8"
            target="_blank"
        >
            Перейти к экзамену по 2 группе
</a>;
    }
}

class RandomExamTwo extends React.Component {
    randomize(myArray) {
        return myArray[Math.floor(Math.random() * myArray.length)];
    }
    render() {
        var arr = [<ExamOneGroupTwo />, <ExamTwoGroupTwo />, <ExamThreeGroupTwo />, <ExamFourGroupTwo />, <ExamFiveGroupTwo />, <ExamSixGroupTwo />, <ExamSevenGroupTwo />, <ExamEightGroupTwo />, <ExamNineGroupTwo />, <ExamTenGroupTwo />, <ExamElevenGroupTwo />, <ExamTwelveGroupTwo />, <ExamThirteenGroupTwo />, <ExamFourteenGroupTwo />, <ExamFifteenGroupTwo />, <ExamSixteenGroupTwo />, <ExamSeventeenGroupTwo />, <ExamEighteenGroupTwo />, <ExamNineteenGroupTwo />, <ExamTwentyGroupTwo />]
        return <div>{this.randomize(arr)}</div>;
    }
}

export default RandomExamTwo